/* Helpers perso */
.mr1em {
  margin-right: 1em;
}


/* Styles spécifiques */
.info-bubble .info-bubble__chat .chat-body .btn {
  height: 60px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.step-customization .input--file {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Message */
.site-club__intro .message{ 
  margin-top: 1em; margin-bottom: 1em;
  padding: 24px;
  background-color: #F4F9FE;
  border: 1px solid #E3ECF4;
  color: #002348;
  border-radius: 12px;
}
  .message .icon-coment-fb {
    margin-right: 1em;
  }

/* Button */

/* Button inviter creation/etape4/verification */
button.btn.btn--primary.btn--full-admin-email {
  width: 100%;
}


@media (min-width: 768px) {
  .banner--site-club .banner__video {
    top: 60%;
  }
}

/* style sur NavLink bloqué */
.link-blocked {
  pointer-events: none;
}

.icon-linkedin:before {
  content: "\e95c";
}
